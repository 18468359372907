@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap");
@mixin breakpoint($point) {
	@if $point==sm {
		@media (min-width: 576px) {
			@content;
		}
	} @else if $point==max-sm {
		@media (max-width: 575px) {
			@content;
		}
	} @else if $point==md {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point==max-md {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point==lg {
		@media (min-width: 992px) {
			@content;
		}
	} @else if $point==max-lg {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point==xl {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $point==max-xl {
		@media (max-width: 1199px) {
			@content;
		}
	} @else if $point==xxl {
		@media (min-width: 1400px) {
			@content;
		}
	} @else if $point==max-xxl {
		@media (max-width: 1399px) {
			@content;
		}
	}
}

:root {
	--title: #ffffff;
	--text: #ffffffb3;
	--body: #010101;
	--section: #151617;
	--dark: #130f26;
	--white: #ffffff;
	--base: #00d39d;
	--btn-hover: #130f26;
	--redish: #f563a9;
	--body-fonts: "Nunito Sans", sans-serif;
	--heading-fonts: "Poppins", sans-serif;
}

body {
	background: var(--body);
	font-family: var(--heading-fonts);
	color: var(--text);
	font-size: 16px;
	line-height: 27.5px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--title);
	font-weight: 700;
}

h1 {
	font-size: 42px;
}
h2 {
	font-size: 34px;
}
h3 {
	font-size: 28px;
}
h4 {
	font-size: 24px;
}
h5 {
	font-size: 20px;
}
h6 {
	font-size: 18px;
}
@include breakpoint(max-md) {
	h1 {
		font-size: 34px;
	}
	h2 {
		font-size: 28px;
	}
	h3 {
		font-size: 24px;
	}
	h4 {
		font-size: 22px;
	}
	h5 {
		font-size: 18px;
	}
	h6 {
		font-size: 16px;
	}
}
@include breakpoint(max-sm) {
	h1 {
		font-size: 28px;
	}
	h2 {
		font-size: 24px;
	}
	h3 {
		font-size: 22px;
	}
	h4 {
		font-size: 20px;
	}
	h5 {
		font-size: 18px;
	}
	h6 {
		font-size: 16px;
	}
}
ul {
	padding: 0;
	margin: 0;
	li {
		list-style: none;
	}
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
a {
	text-decoration: none;
	transition: all ease 0.3s;
}
.font-light {
	font-weight: 300;
}
.font-regular {
	font-weight: 400;
}
.font-medium {
	font-weight: 500;
}
.font-semibold {
	font-weight: 600;
}
.font-bold {
	font-weight: 700;
}
.font-extra-bold {
	font-weight: 800;
}
.font-black {
	font-weight: 900;
}
.container {
	position: relative;
	z-index: 1;
}
@include breakpoint(xl) {
	.container {
		max-width: 1178px;
	}
}
.bg-section {
	background: var(--section);
}
.bg-body {
	background: var(--body);
}
.footer-bottom {
	padding: 37px 0 35px;
	background: var(--section);
}
.footer-bottom-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	align-items: center;
	row-gap: 10px;
	column-gap: 22px;
	font-weight: 500;
	font-size: 12px;
	line-height: 18px;
	.links {
		display: flex;
		flex-wrap: wrap;
		column-gap: 40px;
		row-gap: 10px;
		justify-content: center;
		@include breakpoint(max-sm) {
			column-gap: 20px;
		}
		li {
			a {
				font-size: 16px;
				line-height: 18px;
				color: var(--white);
				&:hover {
					color: var(--base);
				}
			}
		}
	}
	@include breakpoint(md) {
		justify-content: space-between;
	}
}
.footer-widget {
	.title {
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 26px;
	}
}
.footer-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	column-gap: 60px;
	row-gap: 35px;
	.footer-widget {
		&.widget-about {
			width: 100%;
			margin-right: auto;
			@include breakpoint(sm) {
				max-width: 301px;
			}
		}
		&.widget-link {
			width: 100%;
			max-width: 120px;
			ul {
				display: flex;
				flex-direction: column;
				gap: 1px;
				li {
					a {
						color: var(--white);
						line-height: 34px;
						&:hover {
							color: var(--base);
						}
					}
				}
			}
		}
		color: #ffffff;
		font-size: 16px;
		line-height: 24px;
		&.widget-region {
			width: 100%;
			max-width: 305px;
			.text {
				margin-bottom: 25px;
			}
		}
	}
	@include breakpoint(max-xl) {
		column-gap: 40px;
		.footer-widget {
			&.widget-link {
				max-width: 110px;
			}
			&.widget-about {
				max-width: 270px;
				margin: 0;
			}
		}
	}
	@include breakpoint(max-lg) {
		column-gap: 40px;
		.footer-widget {
			&.widget-region {
				max-width: 100%;
			}
		}
	}
	@include breakpoint(max-md) {
		column-gap: 50px;
		justify-content: flex-start;
		row-gap: 40px;
		.title {
			margin-bottom: 18px;
		}
		.footer-widget {
			&.widget-about {
				max-width: 100%;
			}
			&.widget-link {
				max-width: 130px;
			}
		}
	}
	@include breakpoint(max-sm) {
		column-gap: 30px;
	}
}
.widget-contact {
	max-width: 237px;
	ul {
		li {
			display: flex;
			align-items: flex-start;
			gap: 15px;
			color: var(--white);
			font: 1em sans-serif;
			font-size: 16px;
			line-height: 150%;
			span {
				width: 200px;
			}
			&:not(:last-child) {
				margin-bottom: 19px;
			}
		}
	}
}
.social-icons {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	column-gap: 31px;
	li {
		a {
			aspect-ratio: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
		}
	}
}
.footer-top {
	padding: 50px 0 40px;
}

.cmn-btn {
	color: var(--dark);
	padding: 13px 22px;
	display: flex;
	gap: 6px;
	align-items: center;
	display: inline-block;
	font-weight: 700;
	font-size: 14px;
	line-height: 21px;
	text-transform: capitalize;
	border-radius: 6px;
	background: var(--base);
	border: none;
	outline: none;
	box-shadow: none;
	transition: all ease 0.3s;
	text-align: center;
	border: 1px solid var(--base);
	&:hover {
		background: var(--btn-hover);
		color: var(--white);
		border-color: var(--base);
	}
	@include breakpoint(max-sm) {
		padding: 10px 18px;
		font-weight: 600;
	}
	&.secondary-btn {
		background: var(--btn-hover);
		color: var(--white);
		&:hover {
			background: var(--base);
		}
	}
	&.btn-outline {
		background: var(--body);
		color: var(--white);
		border: 1px solid var(--base);
		&:hover {
			background: var(--base);
			border-color: var(--base);
			color: var(--dark);
		}
	}
	&.icon-btn {
		padding-left: 0 !important;
		padding-right: 0 !important;
		width: 40px;
		aspect-ratio: 1;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
/*Header*/
.header-wrapper {
	padding: 18px 0;
	display: flex;
	align-items: center;
	gap: 8px;
	.logo {
		display: block;
		img {
			height: 58px;
			width: 80px;
			object-fit: contain;
			@include breakpoint(max-md) {
				width: 65px;
				height: 45px;
			}
		}
	}
	.menu-area {
		flex-grow: 1;
		display: flex;
		align-items: center;
		gap: 8px;
		column-gap: 23px;
		.menu {
			display: flex;
			align-items: center;
			column-gap: 19px;
			margin-left: auto;
			margin-right: auto;
			li {
				a {
					font-weight: 400;
					font-family: var(--body-fonts);
					font-size: 16px;
					line-height: 24px;
					color: rgba(255, 255, 255, 0.7);
					padding: 8px;
					border-bottom: 1px solid transparent;
					&:hover,
					&.active {
						border-color: var(--white);
					}
				}
			}
		}
		.cmn-btn {
			padding: 13px 30px;
		}
		@include breakpoint(max-xl) {
			.menu {
				li {
					a {
						font-size: 14px;
						font-weight: 600;
					}
				}
			}
		}
		.close {
			display: none;
		}
		@include breakpoint(max-lg) {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			max-width: 340px;
			height: 100vh;
			background: var(--section);
			z-index: 999;
			flex-direction: column;
			padding: 50px 20px 30px;
			justify-content: flex-start;
			align-items: flex-start;
			transition: all ease 0.4s;
			.close {
				position: absolute;
				right: 15px;
				top: 10px;
				width: unset;
				display: block;
			}
			> * {
				width: 100%;
			}
			.search-form {
				margin: 0;
				flex-grow: 0;
				.form-control {
					height: 46px;
					border-radius: 23px;
				}
			}
			.menu {
				width: 100%;
				flex-direction: column;
				margin: 45px 0;
				gap: 6px;
				li {
					width: 100%;
					text-align: center;
					a {
						display: inline-block;
						font-size: 16px;
						font-weight: 500;
					}
				}
			}
			&:not(.active) {
				transform: translateX(-100%);
			}
		}
	}
	.bar {
		display: none;
		@include breakpoint(max-lg) {
			display: block;
		}
	}
}
.header-btns {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 8px;
	.cmn-btn {
		color: #4e486a;
		font-weight: 700;
		font-size: 15px;
		line-height: 20px;
		padding: 11px 10px !important;
		border: 1px solid transparent;
		&:hover {
			color: var(--white);
			border-color: var(--white);
		}
	}
	@include breakpoint(max-lg) {
		.cmn-btn {
			flex-grow: 1;
		}
	}
}
header {
	position: sticky;
	top: 0;
	z-index: 999;
	background: var(--body);
	// border-bottom: 1px solid rgba(207, 219, 213, 0.1);
	.container {
		@include breakpoint(xl) {
			max-width: 1332px;
		}
	}
}
.__dropdown {
	position: relative;
	cursor: pointer;
	&-active {
		background: var(--base);
		color: #4e486a;
		display: flex;
		align-items: center;
		padding: 10px 7px;
		border-radius: 6px;
		font-weight: 700;
		font-size: 15px;
		line-height: 20px;
		min-width: 118px;
		span {
			padding-right: 18px;
			white-space: nowrap;
			margin-right: auto;
		}
		svg {
			path {
				stroke: #ffffff;
			}
		}
	}
	&.select-base {
		.__dropdown-active {
			background: var(--base);
			svg {
				path {
					stroke: #130f26;
				}
			}
		}
	}
	img {
		width: 23px;
		aspect-ratio: 1;
		object-fit: cover;
		margin-right: 13px;
		border-radius: 50%;
	}
	&-menu {
		position: absolute;
		top: 100%;
		right: 0;
		background: var(--section);
		border: 1px solid rgba(#ffffff, 0.2);
		width: 100%;
		min-width: 220px;
		border-radius: 8px;
		z-index: 999;
		li {
			padding: 6px 15px;
			&.active {
				background: var(--base);
			}
			&:first-child {
				border-top-left-radius: 6px;
				border-top-right-radius: 6px;
			}
			&:last-child {
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
			}
		}
		transition: all ease 0.3s;
		&:not(.active) {
			opacity: 0;
			visibility: hidden;
			transform: translateY(10px);
		}
	}
}

.modal .modal-dialog {
	max-width: 739px;
	.modal-close {
		right: 20px;
		top: 20px;
		position: absolute;
		cursor: pointer;
	}
	.modal-content {
		background: #1a1b1f;
		.modal-body {
			padding: 0;
		}
	}
}
.__modal-content {
	&-left {
		width: 295px;
		padding: 16px 29px;
		.title {
			font-weight: 600;
			font-size: 20px;
			line-height: 30px;
			margin-bottom: 33px;
			color: #fffcfc;
		}
		.item {
			.subtitle {
				font-weight: 600;
				font-size: 12px;
				line-height: 18px;
				color: #fffcfc;
				margin-bottom: 16px;
			}
			&:not(:last-child) {
				margin-bottom: 25px;
			}
			ul {
				li {
					&:not(:last-child) {
						margin-bottom: 16px;
					}
					a {
						display: flex;
						align-items: center;
						gap: 7px;
						font-weight: 700;
						font-size: 15px;
						line-height: 22px;
						color: #fffafa;
						img {
							width: 30px;
							aspect-ratio: 1;
							object-fit: contain;
						}
						span {
							width: 0;
							flex-grow: 1;
						}
					}
				}
			}
		}
	}
	&-right {
		padding: 66px 24px 77px;
		// width: 442px;
		width: 0;
		flex-grow: 1;
		border-left: 2px solid var(--base);
		.title {
			font-weight: 700;
			font-size: 24px;
			line-height: 36px;
			margin-bottom: 15px;
			text-align: center;
		}
		&-top {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			gap: 23px;
			img {
				width: 58px;
			}
			div {
				width: 0;
				flex-grow: 1;
				font-weight: 700;
				font-size: 15px;
				line-height: 22px;

				color: #ffffff;
			}
			margin-bottom: 72px;
		}
		.quick-connect {
			display: flex;
			justify-content: center;
			row-gap: 20px;
			column-gap: 50px;
			padding-top: 32px;
			@include breakpoint(max-md) {
				padding-top: 15px;
			}
			img {
				max-width: 100%;
			}
		}
	}
	@include breakpoint(max-md) {
		.__modal-content-left {
			width: 260px;
		}
		.__modal-content-right {
			.quick-connect {
				column-gap: 20px;
				img {
					max-width: 60px;
				}
			}
		}
	}
	@include breakpoint(max-sm) {
		.__modal-content-left {
			width: 100%;
			border-bottom: 2px solid var(--base);
			padding-bottom: 35px;
		}
		.__modal-content-right {
			border: none;
			padding: 40px 16px;
			.title {
				font-size: 20px;
				font-weight: 600;
			}
			.__modal-content-right-top {
				div {
					font-weight: 400;
				}
			}
		}
	}
}
/*Banner Section*/
.banner-section {
	padding: 92px 0 205px;
	position: relative;
	&::before {
		background: linear-gradient(180deg, rgba(1, 1, 1, 0) 0%, #010101 100%);
		height: 105px;
		left: 0;
		bottom: -45px;
		width: 100%;
		content: "";
		position: absolute;
	}
	.container {
		position: relative;
		z-index: 1;
	}
	@include breakpoint(max-lg) {
		padding: 92px 0 120px;
	}
	@include breakpoint(max-sm) {
		padding: 80px 0;
	}
}
.banner-content {
	text-align: center;
	.title {
		text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		font-weight: 600;
		font-size: 51px;
		line-height: 76px;
	}
	.text {
		font-style: italic;
		font-weight: 600;
		font-size: 23px;
		line-height: 34px;
		text-align: center;
		color: #ffffff;
		max-width: 523px;
		margin: 0 auto 30px;
	}
	.btn-grp {
		column-gap: 21px;
		row-gap: 15px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.cmn-btn {
			min-width: 217px;
			justify-content: center;
			letter-spacing: 0.5px;
			font-weight: 500;
			line-height: 27px;
			font-size: 23px;
		}
		margin-bottom: 29.6px;
	}
	@include breakpoint(max-md) {
		.title {
			font-size: 40px;
			line-height: 1.3;
			margin-bottom: 20px;
		}
		.text {
			font-size: 18px;
			font-weight: 500;
			line-height: 1.5;
		}
		.btn-grp {
			.cmn-btn {
				font-size: 18px;
				padding: 10px 15px;
				min-width: 190px;
			}
		}
	}
	@include breakpoint(max-sm) {
		.title {
			font-size: 34px;
		}
		.text {
			font-size: 16px;
		}
	}
}
.counter-wrapper {
	font-weight: 500;
	font-size: 23px;
	line-height: 34px;
	letter-spacing: 0.5px;
	color: #ffffff;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	column-gap: 77px;
	row-gap: 35px;
	.count {
		font-weight: 500;
		font-size: 35px;
		line-height: 52px;
		letter-spacing: 0.5px;
		color: #ffffff;
		margin: 0;
	}
	@include breakpoint(max-lg) {
		column-gap: 40px;
	}
	@include breakpoint(max-md) {
		column-gap: 18px;
		row-gap: 20px;
		.count {
			font-size: 30px;
			line-height: 1.3;
		}
		font-size: 18px;
	}
}
.form-group {
	.form-label {
		font-weight: 500;
		font-size: 23px;
		line-height: 34px;
		color: #51c891;
		margin-bottom: 10px;
	}
	.select-group {
		.form-control {
			position: relative;
			z-index: 1;
			background: transparent;
			box-shadow: none;
			outline: none;
			color: var(--white);
			height: 59px;
			font-weight: 500;
			font-size: 23px;
			text-transform: capitalize;
			border-radius: 6px;
			border: 1px solid var(--base);
			padding-left: 18px;
			padding-right: 25px;
			option {
				background: var(--body);
				font-size: 18px;
			}
			min-width: 179px;
		}
		position: relative;
		.icon {
			position: absolute;
			right: 15px;
			bottom: 15px;
			svg {
				width: 28px;
				path {
					stroke: #ffffff;
				}
			}
		}
	}
	@include breakpoint(max-lg) {
		.form-label {
			font-size: 18px;
		}
		.select-group {
			.form-control {
				font-size: 16px;
				height: 50px;
			}
		}
	}
}
.filter-header {
	column-gap: 50px;
	row-gap: 24px;
	margin-bottom: 45px;
	@include breakpoint(max-lg) {
		row-gap: 16px;
		.form-group {
			flex-grow: 1;
			.select-group {
				min-width: 0;
				.form-control {
					font-size: 14px;
					height: 48px;
				}
				.icon {
					bottom: 10px;
					right: 10px;
				}
			}
			.form-label {
				font-size: 16px;
			}
		}
	}
}
.antex-card {
	background: #1e2023;
	border-radius: 12px;
	padding: 10px 10px 14.73px;

	.antex-top {
		display: flex;
		gap: 8px;
		align-items: center;
		padding: 15px 4px 14.56px;
		img,
		.img {
			text-align: center;
			width: 78px;
			span {
				font-weight: 600;
				font-size: 14px;
				line-height: 21px;
				color: #ffffff;
				margin-bottom: 7px;
			}
		}
		.info {
			width: 0;
			flex-grow: 1;
			.antex-badge {
				background: rgba(#000000, 0.7);
				padding-left: 7px;
				border-radius: 4px;
			}
		}
		.title {
			font-weight: 600;
			font-size: 23px;
			line-height: 34px;
			color: #fff9f9;
			margin-bottom: 6.54px;
			a {
				&:hover {
					color: var(--base) !important;
				}
			}
		}
	}

	.antex-post-meta {
		margin-bottom: 14.85px;
	}
	.text {
		font-weight: 400;
		font-size: 13px;
		line-height: 20px;
		color: #bcbcbc;
		margin-bottom: 20px;
	}
	.antex-softcap {
		margin-bottom: 26.75px;
		li {
			display: flex;
			justify-content: space-between;
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			color: #bcbcbc;
			span:last-child {
				color: var(--white);
			}
		}
	}
	&.antex-card-2 {
		.antex-top {
			.title {
				@include breakpoint(xl) {
					font-size: 32px;
					line-height: 48px;
				}
				@include breakpoint(max-lg) {
					@include breakpoint(md) {
						font-size: 32px;
						line-height: 48px;
					}
				}
			}
			.antex-badge {
				background: rgba(1, 1, 1, 0.29);
				color: var(--base);
				border-radius: 6px;
				text-transform: capitalize;
				@include breakpoint(xl) {
					&::before {
						width: 9px;
						height: 9px;
					}
				}
				@include breakpoint(max-lg) {
					@include breakpoint(md) {
						color: var(--base);
						&::before {
							width: 9px;
							height: 9px;
						}
					}
				}
			}
			.antex-post-meta-item {
				padding: 4.5px 9px;
			}
			@include breakpoint(max-sm) {
				flex-wrap: wrap;
				row-gap: 0;
				img {
					width: 60px;
				}
				.title {
					font-size: 18px;
				}
				.info {
					width: 160px;
				}
			}
		}
		.text {
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
			color: #bcbcbc;
			max-width: 511px;
		}
		@include breakpoint(md) {
			@include breakpoint(max-lg) {
				.antex-social {
					padding-bottom: 8px;
				}
				.antex-softcap {
					gap: 15px;
					display: flex;
					flex-direction: column;
					padding-top: 7px;
					li {
						span {
							font-weight: 600;
							font-size: 16px;
							line-height: 24px;
						}
					}
				}
			}
			@include breakpoint(xl) {
				.antex-social {
					padding-bottom: 8px;
				}
				.antex-softcap {
					gap: 15px;
					display: flex;
					flex-direction: column;
					padding-top: 7px;
					li {
						span {
							font-weight: 600;
							font-size: 16px;
							line-height: 24px;
						}
					}
				}
			}
		}
	}
}
.antex-badge {
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #ffffff;
	text-transform: uppercase;
	&::before {
		content: "";
		width: 5px;
		height: 5px;
		border-radius: 50%;
		margin-right: 7.89px;
		background: var(--base);
	}
	display: flex;
	align-items: center;
	background: rgba(#151617, 0.7);
	border-radius: 5px;
	padding: 5px 11px 4px 4.05px;
	&.inactive {
		&::before {
			background: #e90000;
		}
	}
	@include breakpoint(sm) {
		&.badge-lg {
			font-weight: 600;
			font-size: 22px;
			line-height: 33px;
			padding: 5px 34px 4px 34px;
			&::before {
				width: 9px;
				height: 8px;
			}
		}
	}
}
.antex-post-meta {
	display: flex;
	flex-wrap: wrap;
	gap: 8.89px;
}
.antex-post-meta-item {
	display: flex;
	align-items: center;
	gap: 6.18px;
	padding: 6.02px 4.02px;
	background: rgba(#151617, 0.7);
	border-radius: 5px;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #ffffff;
	&:hover {
		color: #fff;
	}
}
.antex-progress {
	background: rgba(1, 1, 1, 0.55);
	border-radius: 5px;
	padding: 5.62px 11.42px 22.45px;
	font-weight: 400;
	font-size: 14px;
	line-height: 21px;
	color: var(--white);
	.subtitle {
		color: var(--white);
	}
	margin-bottom: 15.75px;
	.antex-progress-top {
		margin-bottom: 6.85px;
	}
	.antex-progress-bottom {
		font-weight: 300;
		font-size: 10px;
		line-height: 15px;
		color: #ffffff;
		margin-top: 6.4px;
	}
}
.progress {
	height: 17px;
	border-radius: 7px;
	border: 1px solid var(--base);
	background: transparent;
	.progress-bar {
		background: var(--base);
		border-radius: 0 7px 7px 0;
		font-weight: 500;
		font-size: 12px;
		line-height: 18px;
		color: #000000;
	}
}
.antex-social {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 17px;
	li {
		a {
			display: block;
		}
	}
}
.antext-row {
	@include breakpoint(xl) {
		column-gap: 52px;
		div[class*="col"] {
			max-width: 358px;
			margin-bottom: 32px;
		}
	}
}
.__pagination {
	display: flex;
	flex-wrap: wrap;
	li {
		width: 36px;
		aspect-ratio: 1;
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition: all ease 0.3s;
		&.active {
			background: var(--base);
		}
	}
}
.antex-presale {
	padding: 7px 15px 7px 14px;
	border-radius: 12px;
	border: 1px solid var(--base);
	background: #1e2023;
	display: flex;
	align-items: center;
	gap: 19.77px;
	margin-right: 6px;
	position: relative;
	&::before {
		content: "";
		top: 50%;
		left: -4px;
		transform: translateY(-50%);
		border-radius: 50%;
		width: 8px;
		height: 8px;
		background: var(--base);
		position: absolute;
	}
	img {
		width: 61px;
	}
	h6 {
		font-weight: 600;
		font-size: 12px;
		line-height: 18px;
		color: #bcbcbc;
		margin-bottom: 4px;
	}
	font-weight: 600;
	font-size: 10px;
	line-height: 15px;
	color: #bcbcbc;
	ul {
		li {
			display: flex;
			justify-content: space-between;
			column-gap: 16px;
		}
	}
}
.antext-section {
	padding: 93px 0;
}
.__antext-top {
	margin: -10px -10px 0;
	padding: 5px 13px 58px;
}
.__presale-address {
	padding: 25px;
	background: #1e2023;
	border-radius: 12px;
	ul {
		li {
			padding: 8px 0;
			border-bottom: 1px solid rgba(#00e9ae, 0.23);
			display: flex;
			justify-content: space-between;
			@include breakpoint(xl) {
				font-weight: 500;
				font-size: 20px;
				line-height: 30px;
				color: #aeaeae;
				padding: 10px 0;
				span {
					&:nth-child(even) {
						color: var(--base);
					}
				}
			}
		}
	}
}
.__whitelist-card {
	padding: 25px 20px 45px;
	background: #1e2023;
	border-radius: 12px;
	.enter-whitelist-btn {
		margin-bottom: 25px;
	}
}
.enter-whitelist-add-btn {
	background: #131416;
	border-radius: 12px;
	font-size: 40px;
	line-height: 48px;
	height: 80px;
	width: 180px;
	color: var(--base);
	border: none;
	outline: none;
	margin-bottom: 19px;
	@include breakpoint(max-sm) {
		height: 60px;
		font-size: 24px;
	}
}
.grp-btn {
	gap: 22px;
	.enter-whitelist-add-btn {
		height: 57px;
		width: 137px;
		margin: 0;
		@include breakpoint(max-sm) {
			height: 50px;
			width: 100px;
		}
	}
}
.enter-whitelist-btn {
	height: 163px;
	background: #131416;
	border-radius: 12px;
	font-weight: 400;
	font-size: 40px;
	line-height: 48px;
	color: var(--base);
	text-transform: capitalize;
	width: 100%;
	border: none;
	outline: none;
	@include breakpoint(max-sm) {
		font-size: 24px;
		height: 90px;
	}
}
.address-whitelisted {
	font-weight: 400;
	font-size: 22px;
	line-height: 39px;
	color: #ffffff;
	padding: 21px 20px 22px;
	background: #1e2023;
	border-radius: 12px;
	max-width: 532px;
	position: relative;
	display: flex;
	align-items: center;
	margin: 0 auto 16px;
	&::before {
		content: "";
		width: 25px;
		height: 25px;
		border-radius: 50%;
		background: var(--base);
		display: inline-block;
		margin: 0 30px;
		@include breakpoint(xl) {
			margin: 0 42px 0 52px;
		}
	}
	span {
		margin-right: auto;
		display: block;
	}
	@include breakpoint(max-xl) {
		@include breakpoint(lg) {
			font-size: 24px;
			&::before {
				width: 20px;
				height: 20px;
			}
		}
	}
	@include breakpoint(max-md) {
		font-size: 24px;
		padding: 15px;
		&::before {
			width: 20px;
			height: 20px;
		}
	}
	@include breakpoint(max-sm) {
		font-size: 18px;
		&::before {
			width: 15px;
			height: 15px;
		}
	}
}
.copy-stats {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	input {
		background: transparent;
		color: var(--white);
		box-shadow: none;
		border: none;
		outline: none;
	}
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: #ffffff;
	gap: 10px;
	button {
		background: transparent;
		outline: none;
		border: none;
	}
}
.antex-card-2 {
	.antex-top-2 {
		@include breakpoint(sm) {
			.info {
				padding-top: 24px;
			}
		}
		@include breakpoint(sm) {
			padding: 15px;
		}
		@include breakpoint(max-sm) {
			.info {
				width: 320px !important;
				.copy-stats {
					font-size: 12px;
					margin-top: 15px;
				}
			}
		}
	}
	.progress {
		max-width: 260px;
		font-weight: 600;
		font-size: 14px;
		line-height: 21px;
		color: #130f26;
	}
	.copy-stats {
		max-width: 360px;
	}
}
.__container {
	@include breakpoint(xl) {
		max-width: 1286px;
	}
}
.allocation-title {
	font-weight: 600;
	font-size: 14px;
	line-height: 21px;
	color: #ffffff;
}
.bg-131416 {
	background: #131416;
}

.range-wrapper {
	margin-top: 30px;
	padding: 32px 20px 23px;

	background: rgba(1, 1, 1, 0.55);
	opacity: 0.7;
	border-radius: 12px;
	.range-input-wrapper {
		font-size: 18px;
		line-height: 22px;
		color: #ffffff;
		span {
			margin-left: 26px;
		}
	}
	@include breakpoint(max-sm) {
	}
}

.single-thumb .range-slider__thumb[data-lower] {
	width: 0;
}
.single-thumb .range-slider__thumb[data-upper] {
	height: 25px !important;
	width: 25px !important;
	background: var(--base) !important;
}
.single-thumb .range-slider__range {
	background: var(--base) !important;
	border-radius: 0 !important;
	@include breakpoint(max-sm) {
		height: 10px !important;
	}
}
.single-thumb {
	border-radius: 0 !important;
	height: 15px !important;
	background: #1e2023 !important;
	@include breakpoint(max-sm) {
		height: 10px !important;
	}
}
.range-wrapper-top {
	display: flex;
	input {
		background: transparent;
		border: none;
		outline: none;
		color: var(--base);
		padding: 0;
		// width: 85px;
		// width: 65px;
		font-weight: 500;
		padding-right: 8px;
		text-align: center;
	}
	font-weight: 500;
	color: var(--base);
	font-size: 30px;
	line-height: 45px;
	@include breakpoint(max-sm) {
		font-size: 24px;
	}
	.value-container {
		position: relative;
		flex-grow: 1;
		text-align: center;
		.input-1 {
			// opacity: 0;
			// position: absolute;
			// left: 0;
			// text-align: center;
			// top: 0;
			// z-index: -1;
			// width: 100%;
			// height: 100%;
			display: none;
		}
	}
	.minus {
		// margin-right: auto;
		cursor: pointer;
		width: 25px;
		text-align: center;
	}
	.plus {
		// margin-left: auto;
		cursor: pointer;
		width: 25px;
		text-align: center;
	}
	margin-bottom: 15px;
}
.range-wrapper-inner {
	max-width: 381px;
	margin: 0 auto;
}
.rates-counts {
	a {
		border-radius: 12px;
		padding: 2px 5px;
		color: var(--base);
		font-size: 34px;
		line-height: 42px;
		font-weight: 600;
		background: #131416;
		@include breakpoint(max-xl) {
			@include breakpoint(lg) {
				font-size: 20px;
				padding: 2px 15px 0px;
			}
		}
		@include breakpoint(max-lg) {
			font-size: 20px;
			padding: 2px 15px 0px;
		}
		@include breakpoint(max-sm) {
			font-size: 18px;
			padding: 2px 10px 0px;
		}
	}
	@include breakpoint(max-sm) {
		gap: 10px !important;
	}
}
.buy-btn {
	font-weight: 700;
	font-size: 24px;
	line-height: 29px;
	color: #130f26;
	width: 100%;
	max-width: 397px;
	&:hover {
		background: var(--base);
		color: var(--dark);
	}
	margin-bottom: 25px;
	margin-top: 38px;
	@include breakpoint(max-sm) {
		font-size: 18px;
		height: 42px;
		padding-top: 0;
		padding-bottom: 0;
	}
}
.antex-contribute-info {
	li {
		display: flex;
		justify-content: space-between;
		font-weight: 500;
		font-size: 20px;
		line-height: 30px;
		color: #aeaeae;
		border-color: rgba(#00e9ae, 0.23) !important;
		span:last-child {
			color: var(--base);
		}
		@include breakpoint(max-sm) {
			font-size: 16px;
			font-weight: 400;
		}
	}
}

.radial-chart {
	background: #1e2023;
	border-radius: 12px;
	path[stroke="rgba(242,242,242,0.85)"] {
		stroke: #232b35;
		stroke-width: 4px;
	}
	path[stroke-linecap="butt"] {
		stroke-width: 4px;
	}
	text {
		fill: #fff;
	}
}
.chart-info {
	padding: 0 34px 30px;
	li {
		font-size: 14px;
		line-height: 24px;
		display: flex;
		justify-content: space-between;
		@include breakpoint(md) {
			@include breakpoint(max-lg) {
				font-weight: 500;
				font-size: 21px;
				line-height: 32px;
			}
		}
		@include breakpoint(xl) {
			font-weight: 500;
			font-size: 21px;
			line-height: 32px;
		}
		border-bottom: 1px solid rgba(#00e9ae, 0.23);
		padding: 11px 0;
		span:last-child {
			color: var(--title);
		}
		position: relative;
		.indicator {
			border-radius: 8px;
			width: 12px;
			height: 12px;
			position: absolute;
			background: var(--base);
			left: 0;
			top: 17px;
			@include breakpoint(md) {
				@include breakpoint(max-lg) {
					width: 28px;
					height: 27px;
					border-radius: 50%;
					top: 13px;
				}
			}
			@include breakpoint(xl) {
				width: 28px;
				height: 27px;
				border-radius: 50%;
				top: 13px;
			}
		}
		padding-left: 20px;
		@include breakpoint(md) {
			@include breakpoint(max-lg) {
				padding-left: 40px;
			}
		}
		@include breakpoint(xl) {
			padding-left: 40px;
		}
	}
}
.token-deployer {
	background: var(--base);
	font-weight: 600;
	font-size: 12px;
	line-height: 18px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	color: #130f26;
	max-width: 626px;
	border-radius: 7px;
	gap: 20px;
	align-items: center;
	margin: 0 auto;
	.left-cont {
		flex-grow: 1;
		width: 260px;
	}
	h6 {
		font-weight: 600;
		font-size: 28px;
		line-height: 42px;
		color: #000000;
		margin: 0;
		@include breakpoint(max-sm) {
			font-size: 22px;
		}
	}
	.cmn-btn {
		background: #130f26;
		border-radius: 12px;
		color: var(--base);
		font-weight: 600;
		font-size: 24px;
		line-height: 36px;
		color: #00e9ae;
		min-width: 128px;
		text-transform: uppercase;
		padding-top: 10px;
		padding-bottom: 10px;
		line-height: 24px;
	}
	padding: 12px;
	&:hover {
		color: var(--dark);
	}
}
.create-ido-title {
	font-weight: 600;
	font-size: 28px;
	line-height: 42px;
	padding: 68px 0;
	margin: 0;
	text-align: center;
	@include breakpoint(max-lg) {
		padding: 40px 0;
	}
	@include breakpoint(max-sm) {
		padding: 35px 0;
	}
}
.create-ido-container {
	background: #1e2023;
}
.create-ido-item {
	padding-left: 20px;
	padding-right: 20px;
	.inner {
		max-width: 636px;
		margin: 0 auto;
	}
	.subtitle {
		font-weight: 500;
		font-size: 24px;
		line-height: 36px;
		/* identical to box height */

		color: #aeaeae;
	}
	&:not(:last-child) {
		border-bottom: 2px solid var(--base);
	}
	.input--group {
		margin-bottom: 21px;
	}
	@include breakpoint(max-md) {
		.subtitle {
			font-size: 20px;
		}
	}
}
.input--group {
	display: flex;
	flex-wrap: wrap;
	gap: 13px;
	.form-control {
		height: 60px;
		background: #010101;
		border-radius: 12px;
		box-shadow: none;
		outline: none;
		border: none;
		font-size: 24px;
		color: var(--white);
		width: auto;
		flex-grow: 1;
		&::placeholder {
			color: #aeaeae;
		}
	}
	.cmn-btn {
		font-weight: 600;
		font-size: 24px;
		line-height: 36px;
		color: #000000;
		height: 60px;
		padding-top: 0;
		border-radius: 12px;
		padding-bottom: 0;
		&:hover {
			background: var(--base);
			color: var(--dark);
		}
	}
	@include breakpoint(max-md) {
		.form-control,
		.cmn-btn {
			height: 50px;
			font-size: 18px;
		}
	}
	@include breakpoint(max-sm) {
		.form-control,
		.cmn-btn {
			height: 45px;
			font-size: 16px;
		}
	}
}
.info-list {
	background: #010101;
	padding: 15px 20px;
	border-radius: 12px;
	ul {
		font-weight: 500;
		font-size: 20px;
		line-height: 30px;
		color: #aeaeae;
		display: flex;
		flex-direction: column;
		gap: 9px;
		li {
			display: flex;
			justify-content: space-between;
			span:last-child {
				color: #00e9ae;
			}
		}
		@include breakpoint(max-sm) {
			gap: 6px;
			font-size: 16px;
		}
	}
	&.transparent {
		background: transparent;
		padding: 0;
		ul {
			gap: 3px;
		}
	}
}
.__form-group {
	.form-label {
		font-weight: 500;
		font-size: 15px;
		line-height: 22px;
		text-align: center;
		color: #aeaeae;
		margin: 0;
		text-align: center;
		display: block;
		margin-bottom: 10px;
	}
	.form-control {
		height: 80px;
		background: #010101;
		border-radius: 12px;
		box-shadow: none;
		outline: none;
		border: none;
		font-size: 24px;
		color: var(--white);
		text-align: center;
		&::placeholder {
			color: #aeaeae;
		}
	}
	@include breakpoint(max-md) {
		.form-control {
			height: 50px;
			font-size: 18px;
		}
	}
}
button.cmn-btn {
	&:hover {
		background: var(--base);
		color: var(--dark);
	}
}
.cmn-btn.btn--lg {
	height: 62px;
	min-width: 207px;
	font-weight: 600;
	font-size: 24px;
	line-height: 36px;
	text-transform: uppercase;
}
.statistics-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10.29px;
	.statistics-card {
		padding: 10px 16px;
		background: #1e2023;
		border-radius: 12px;
		.subtitle {
			font-weight: 400;
			font-size: 18px;
			line-height: 27px;
			color: var(--base);
			margin-bottom: 1px;
		}
		.title {
			color: var(--base);
			font-weight: 600;
			font-size: 28px;
			line-height: 42px;
			margin: 0;
		}
		width: 100%;
		max-width: 218px;
		@include breakpoint(max-sm) {
			.title {
				font-size: 20px;
				line-height: 1.4;
			}
			.subtitle {
				font-size: 16px;
			}
		}
	}
}
.ido-container {
	max-width: 989px;
}
.stakeboard-card {
	background: #1e2023;
	border-radius: 12px;
	padding-left: 4px;
	padding-right: 4px;
	.title {
		text-align: center;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		padding: 13px 11px;
		margin: 0;
	}
	.cmn-btn {
		height: 45px;
		width: 100%;
		max-width: 218px;
		font-size: 20px;
		font-weight: 600;
	}
}

.stakeboard-card-item {
	background: #131416;
	border: 1px solid var(--base);
	border-radius: 12px;
	flex-grow: 1;
	max-width: 218px;
	text-align: center;
	padding: 10px 12px;
	.subtext {
		font-weight: 400;
		font-size: 18px;
		line-height: 27px;
		margin: 0;
		color: var(--base);
	}
	.subtitle {
		font-weight: 600;
		font-size: 28px;
		line-height: 42px;
		color: var(--base);
		margin: 0;
	}
	@include breakpoint(max-sm) {
		.subtext {
			font-size: 14px;
		}
		.subtitle {
			font-size: 22px;
			line-height: 1.4;
		}
	}
}
.__table {
	color: var(--white);
	border-spacing: 0;
	border-collapse: separate;
	thead {
		tr {
			th {
				border: none;
				font-weight: 600;
				font-size: 12px;
				line-height: 18px;
				color: #ffffff;
				@include breakpoint(max-sm) {
					font-size: 10px;
					white-space: nowrap;
				}
			}
		}
	}
	tbody {
		tr {
			border-radius: 7px;
			td {
				border: none;
				background: #131416;
				vertical-align: middle;
				border-bottom: 2px solid #1e2023;
				&:first-child {
					border-bottom-left-radius: 7px;
					border-top-left-radius: 7px;
				}
				&:last-child {
					border-bottom-right-radius: 7px;
					border-top-right-radius: 7px;
				}
				@include breakpoint(xl) {
					font-weight: 600;
					font-size: 20px;
					line-height: 30px;
				}
			}
		}
	}
	@include breakpoint(max-sm) {
		font-size: 14px;
	}
}
.copy-address {
	display: flex;
	align-items: center;
	gap: 18px;
	input {
		background: transparent;
		color: var(--white);
		border: none;
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
	}
	button {
		background: transparent;
		border: none;
		outline: none;
		box-shadow: none;
		font-size: 8px;
		svg {
			width: 22px;
		}
	}
	@include breakpoint(max-xl) {
		@include breakpoint(lg) {
			input {
				font-size: 16px;
			}
			button {
				svg {
				}
			}
		}
	}
	@include breakpoint(max-sm) {
		gap: 8px;
		input {
			font-size: 14px;
		}
		button {
			padding: 0;
			svg {
				width: 16px;
			}
		}
	}
}
.stake-withdraw-card {
	background: #1e2023;
	border-radius: 12px;
	padding: 42px 20px;
	.cmn-btn {
		@include breakpoint(max-sm) {
			height: 50px;
			padding-top: 0;
			padding-bottom: 0;
			font-size: 20px;
		}
	}
}
.stake-withdraw-card-menu {
	display: flex;
	li {
		width: 50%;
		font-weight: 600;
		font-size: 23px;
		line-height: 34px;
		text-transform: uppercase;
		color: var(--white);
		border-radius: 12px;
		cursor: pointer;
		height: 72px;
		display: flex;
		align-items: center;
		justify-content: center;
		&.active {
			background: var(--base);
		}
		@include breakpoint(max-md) {
			font-size: 18px;
			height: 56px;
		}
		@include breakpoint(max-sm) {
			font-size: 16px;
			height: 50px;
		}
	}
	background: #010101;
	border-radius: 12px;
	border: 1px solid #00e9ae;
}
.__stake {
	.info-list {
		margin-top: 32px;
	}
	.rates-counts {
		margin-top: 22px;
	}
	.range-wrapper {
		margin-top: 22px;
	}
	.cmn-btn {
		width: 100%;
		max-width: 412px;
		margin: 38px auto 45px;
	}
}
.range-wrapper .range-input-wrapper span {
	color: var(--white);
}
.duration-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin-top: 29px;
	.duration-item {
		flex-grow: 1;
		.subtxt {
			font-weight: 400;
			font-size: 18px;
			line-height: 27px;
			color: var(--base);
		}
		.subtitle {
			font-weight: 600;
			font-size: 28px;
			line-height: 42px;
			/* identical to box height */

			color: #aeaeae;
		}
		padding: 10px 25px;
		background: #010101;
		border-radius: 12px;
	}
	@include breakpoint(max-sm) {
		.duration-item {
			.subtxt {
				font-size: 16px;
			}
			.subtitle {
				font-size: 20px;
				margin: 0;
			}
		}
	}
}
input[type="number"] {
	&::-webkit-inner-spin-button,
	&::-webkit-inner-outer-button {
		display: none;
	}
}
